
.loginWrap{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    background-color: var(--black);
    &.pc{
        position: fixed;
        z-index: 999;
        left: 0;
        align-items: flex-end;
        .header{
            height: 160px;
            background-image: url(../../../../public/UI/Picture/UI_Picture-Web_Top_02.png); 
            padding: 0 30px;
            padding-top: 20px;
            
        }
        
        .mid{
            width: 60%;
            overflow: hidden;
            position: relative;
            justify-content: flex-start;
            .t{
                div{
                    font-size: 126px;
                    
                }
            }
            .b{
                .bt{
                    font-size: 48px;
                }
                .bm{
                    font-size: 82px;
                }
                .bb{
                    font-size: 48px;
                }
            }
            .startBtn{
                position: absolute;
                right: 60px;
                bottom: 0;
            }
        }
        .foot{
            height: 180px;
            background-image: url(../../../../public/UI/Picture/UI_Picture-Web_Down_02.png); 
            background-position: top center;
            padding: 0 30px;
            justify-content: flex-end;
            padding-bottom: 20px;
            overflow-x: hidden;
            .ft{
                
                .ftl{
                    font-size: 42px;
                }
            }
            .iconList{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;
                overflow-x: auto;
                // width: 100%;
                animation: roll 60s linear infinite;
                .item{
                    // justify-content: center;
                    // flex-grow: 0;
                    // flex-shrink: 0;
                    margin-right: 20px;
                }
            }
            // .iconList::-webkit-scrollbar {
            //     width: 5px;
            //     height: 8px;
            //     background-color: #000;
            //     display: block;
            //   }
              
            //   .iconList::-webkit-scrollbar-thumb {
            //     background: #aaa;
            //   }
        }
    }
    .bg{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        object-fit: cover;
    }
    .header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 15px;
        padding-top: 10px;
        height: 85px;
        background-image: url(../../../../public/UI/Picture/UI_Picture-Web_Top_01.png);
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        z-index: 1;
        .r{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .mid{
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        z-index: 1;
        .t{
            div{
                font-size: 35px;
                font-weight: bold;
            }
        }
        .b{
            display: flex;
            flex-direction: column;
            .bt{
                width: 100%;
                font-size: 24px;
                color: var(--TP-blue-light);
                text-align: right;
                font-weight: bold;
            }
            .bm{
                width: 100%;
                font-size: 40px;
                font-weight: bold;
                text-align: center;
            }
            .bb{
                font-size: 24px;
                color: var(--TP-blue-light);
                text-align: left;
                font-weight: bold;
            }
        }
    }
    .foot{
        width: 100%;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 150px;
        background: url(../../../../public/UI/Picture/UI_Picture-Web_Down_01.png);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        z-index: 1;
        .ft{
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            .ftl{
                font-size: 20px;
                font-weight: bold;
            }
            .ftr{
                margin-right: 20px;
            }
        }
        .fb{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;
        }
    }
}
.loginSilde{
    display: flex !important;
    align-items: center;
    justify-content: space-evenly;
}

@keyframes roll {
    0% {
      transform: translateX(0%);
    }
    100% {
    //   transform: translateX(calc(-50% + 100vw));
      transform: translateX(-50%);
    }
  }

.loadingWrap{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: var(--black);
    .logo{
        width: 160px;
        height: 160px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
    }
    .text{
        margin-top: 40px;
        font-size: 30px;
        color: #EEEEEE;
        font-weight: bold;
    }
}
