.wrap{
    //background-size: cover;
    //background-repeat: no-repeat;
    //background-position: center;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    .content{
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .btnBg{
            //width: 100%;
            //height: 100%;
            left: 50%;
            top: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
        }
        .childrenContent{
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .prefixIcon{
            position: absolute;
            top: 48%;
            transform: translate(-50%, -50%);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
