.banner{
  width: 100%;
  background-image: url(../../../public/UI/Banner/UI_Banner_ZETACHAIN_01.webp);
  background-position: top center;
  background-size: cover;
  // padding: 20px 0px 40px 0;
  padding: 5px 0;
  min-height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
  .logo{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    bottom: 5px;

  }
  .points{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 5px;
  }
  .showTimeerShare {
    width: 250px;
    height: 28px;
    line-height: 28px;
    opacity: 0.8;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 21.76%, rgba(35, 35, 35, 1) 51.04%, rgba(0, 0, 0, 1) 79.07%, rgba(84, 84, 84, 0) 100%);

  }
}