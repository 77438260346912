.wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: var(--content);
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 35px;
        font-size: 20px;
        color: var(--white);
    }
    .settingLine{
        .settingLineLeft{
            width: 150px;
            text-align: left;
        }
        .settingLineRight{
            width: 150px;
            text-align: left;
        }
    }
    .drawCon{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 10px;
    }
    .bottom{
        display: flex;
        align-items: center;
        justify-content: space-between;
        button{
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.black{
    background: rgba(255,255,255, 0.3);
    border: none;

    span{
        color: #ffffff;
    }
}

.momentWrap{
    margin-top: 7px;
    width: 100%;
    .momentTitle{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #EEEEEE;
        font-weight: 400;
        font-family: var(--font-moment) !important;
    }
    .settingLine{
        width: 100%;
        position: relative;
        .settingLineLeft{
            margin-left: 55px;
            width: 114px;
            text-align: left;
            font-family: var(--font-moment) !important;
            font-weight: 400;
        }
        .settingLineRight{
            width: 77px;
            text-align: left;
            font-family: var(--font-moment) !important;
            font-weight: 400;
        }
        .lineTitle{
            color: var(--TP-moment-gray);
            font-size: 12px;
        }
        .lineText{
            color: var(--TP-moment-light-gray);
            font-size: 14px;
        }
        .checkBox{
            position: absolute;
        }
        .checkBox{
            left: 30px;
        }
        .uncheckedBox{
            width: 14px;
            height: 14px;
            border-radius: 7px;
            background: rgba(0,0,0,0.32);
            border: 1px solid rgba(255,255,255,1);
        }
    }
    .momentDrawCon{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 21px;
    }
    .bottom{
        display: flex;
        align-items: center;
        justify-content: space-between;
        button{
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
