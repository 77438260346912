body {
  margin: 0;
  padding: 0;
  touch-action: none;
}
@font-face {
  font-family: "RaceSport";
  src: url(./assets/fonts/RaceSport.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "ProtestStrikeRegular";
  src: url(./assets/fonts/ProtestStrikeRegular.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "ProtestStrikeRegular";
  src: url(./assets/fonts/ProtestStrikeRegular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "ProtestStrikeLight";
  src: url(./assets/fonts/ProtestStrikeRegular.ttf) format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "ProtestStrikeLight";
  src: url(./assets/fonts/ProtestStrikeLight.ttf) format("truetype");
  font-weight: normal;
}

/* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none)  {
    html {
      min-height: 100.3%;
      overscroll-behavior-y: none;
    }
  }
}
/* prevent pull-to-refresh for Safari 9~15 */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none))  {
    html {
      height: 100%;
      overflow: hidden;
    }
    body {
      margin: 0px;
      max-height: 100%; /* or `height: calc(100% - 16px);` if body has default margin */
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
    /* in this case to disable pinch-zoom, set `touch-action: pan-x pan-y;` on `body` instead of `html` */
  }
}
/* prevent pull-to-refresh for Chrome 63+ */
body{
  overscroll-behavior-y: none;
}

:root{
  --bg:#0c0e0f;
  --black:#000;
  --color-gray:#c3c3c3;
  --color-keyGold: #f0b90b;
  --gray-bg:#2c2c2c;
  --content:#fff;
  --noreadybtn:#25252a;
  --title:var(--content);
  --tips:#999da1;
  --a:#8291F9;
  --gray-title-color:#f2f2f2;
  --gray-btn:#f2f2f2;
  --border:#C3C3C3;
  --border-gray:#707070;
  --white:#fff;
  --privy:rgb(0, 186, 250);
  --red:#f63030;
  --red-moment:#FD6866;
  --red-5:rgba(246,48 , 48, 0.5);
  --pink:#ffebef;
  --green: #47c95f;
  --green-up:#00FF14;
  --green-moment:#89FD64;
  --green-up-bg: #0e4a12;
  --red-down-bg: rgba(242,13,13,.3);
  --gray-300: #dee2e6;
  --gray-btn-bg:#f5f5f5;
  --gray-border-color:#bdbdbd;
  --gray-input-color:#a6a6a6;
  --fire-color:#f49e0a;
  --yellow:#fab600;
  --bg-black-5:rgba(0,0,0,.5);
  --bg-black-35:rgba(0,0,0,.3);
  --bg-black-55:rgba(0,0,0,.55);
  --bg-white-5:rgba(255,255,255,.5);
  --bg-white-35:rgba(255,255,255,.35);
  --nav-bg:#1a1a20;
  --dark-black:#232227;
  --dark-black-content:#252525;
  --dark-black-div:#3d3e43;
  --avatar-border:#bfc0c6;
  --dark-border:#46474c;
  --btn-border-red:#D60036;
  --btn-bg-red:#372629;
  --btn-border-gray:#434345;
  --btn-bg-gray:#1c1c1c;
  --btn-color-red:#d63151;
  --btn-color-black:#0c0e0f;
  --input-bg:#181a1b;
  --message-bg:#8291f9;
  --btn-active-bg:#525ce0;
  --btn-color-green:#3FB544;
  --text-bg-gray:#515155;
  --btn-select-color:#7357F6;
  --tag-wish-color:#7357F6;
  --btn-right-color:#3FB544;
  --btn-wrong-color:#D60036;
  --color-hosts-red:#fd2028;
  --color-energy:#FFBF00;
  --color-heat:#D40136;
  --gold:#fae770;
  --bg-black-70:rgba(0,0,0,.7);
  --primary-color: rgb(197, 161, 89);
  --primary-gradient: linear-gradient(90deg, #D60036, #8200C7);
  --primary-gradient-verse: linear-gradient(90deg, #8200C7, #D60036);
  --green-gradient: linear-gradient(90deg, #3FB544 , #2A772D);
  --primary-text-color: #fff;
  --accent-gradient: linear-gradient(90deg, #660001, #11041e);


  /* new UI */
  --TP-blue:#02308d;
  --TP-blue-light:#73c1e4;
  --TP-blue-Progress:#009CED;
  --TP-bg-gray:#1c2633;
  --TP-yellow:#ffda0a;
  --TP-gray:#2c2c2c;
  --TP-red:#ff6a90;
  --TP-green:#00ff14;
  --TP-purple:#da95ff;
  --TP-lfg-gray:#f2fff7;
  --TP-light-gray:#B3B3B3;
  --TP-moment-gray:#999999;
  --TP-moment-light-gray:#BBBBBB;
  --model-bg-color:#0d2458;
  --model-border-color:#00b5fd;
  --item-bg: rgba(0,0,0,.19);
  --bg-white-2:rgba(255,255,255,.2);
  --btn-disable-color:#B3B3B3;
  --btn-disable-bg-color:#555555;
  --TP-blue-02: #4096c9;
  --moment-bg-balck: rgba(0,0,0,1);

  --usable-width: 500px;
  --unpackcard-width: 90px;

  --font-moment: "Roboto", Arial, Helvetica, sans-serif;
}
html,body{
  background-image: url(../public/UI/BG/UI_Background_Back_01.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background: var(--accent-gradient); */
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

#root,.App{
  background-size: cover;
  background-position: center;
  height: 100%;
  /*background-image: url(../public/UI/BG/UI_Background_SpeedLine_01.webp);*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}


.publicBg{
  background-image: url(../public/UI/BG/UI_Background_SpeedLine_01.webp);
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
}


*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'ProtestStrikeLight', 'ProtestStrikeRegular', 'Roboto', sans-serif !important;
  color: var(--content);
  font-size: 14px;
}



.momentFont{
  font-family: var(--font-moment) !important;
}


.mt0{
  margin-top: 0 !important;
}
.fw-100{
  font-weight: 100;
}

::-webkit-scrollbar {
  width: 0px;
  display: none;
}
.pa {
    position: absolute;
}
.pr {
    position: relative;
}
a{
  color: white !important;
}
input::placeholder{
  color: var(--TP-moment-gray) !important;
  font-family: var(--font-moment) !important;
}
textarea::placeholder{
  color: var(--TP-moment-gray) !important;
  font-family: var(--font-moment) !important;
}

.fb{
  font-weight: bold;
}
.f900{
  font-weight: 900;
}
.fl{
  font-weight: 200;
}
.f400{
  font-weight: 200;
}
.fl {
  font-weight: lighter
}
.fn {
  font-weight: normal;
}
.ant-input{
  color: var(--content);
}
.searchInput .ant-input{
  color: #707070;
  font-family: var(--font-moment) !important;
}
.searchInput .ant-input-affix-wrapper{
  color: #707070;
  font-family: var(--font-moment) !important;
}
.color-999{
  color: #999 !important;
}
.color-333{
  color: #333 !important;
}
.color-blue{
  color: var(--TP-blue) !important;
}
.color-blue-light{
  color: var(--TP-blue-light) !important;
}
.color-lfg{
  color: var(--TP-lfg-gray) !important;
}
.color-active{
  color: var(--message-bg);
}
.color-light-gray{
  color: var(--TP-light-gray) ;
}
.color-moment-gray{
  color: var(--TP-moment-gray) !important;
}
.color-EEEEEE{
  color: #EEEEEE !important;
}
.color-moment-light-gray{
  color: var(--TP-moment-light-gray) !important;
}
.color-red{
  color: var(--red) !important;
}
.color-red-moment{
  color: var(--red-moment) !important;
}
.color-hosts-red{
  color: var(--color-hosts-red) !important;
}
.color-green{
  color: var(--TP-green) !important;
}
.color-greenup{
  color: var(--green-up) !important;
}
.color-green-moment{
  color: var(--green-moment) !important;
}
.color-white{
  color: var(--white) !important;
}
.color-black{
  color:  var(--black) !important;
}
.color-yellow{
  color: var(--TP-yellow) !important;
}
.color-purple{
  color: var(--TP-purple) !important;
}
.color-wish-color{
  color: var(--TP-purple) !important;
}
.color-red{
  color: var(--TP-red) !important;
}
.color-gray{
  color: var(--TP-gray) !important;
}
.c-a{
  color: var(--a) !important;
}
.c-title{
  color: var(--white) !important;
}
.overflowHidden{
  overflow: hidden;
}
.fs10{
  font-size: 10px !important;

}
.fs10 span{
  font-size: 10px;
}
.fs22{
  font-size: 22px;
}
.fs11{
  font-size: 11px;
}
.fs14{
  font-size: 14px;
}
.lh1 {
    line-height: 1;
}
.lh14 {
    line-height: 14px;
}
.fs12{
  font-size: 12px !important;
}
.fs13{
  font-size: 13px;
}
.color-energy{
  color: var(--color-energy);
}
.color-keyGold{
  color: var(--color-keyGold);
}
.color-primary-text{
  color: var(--primary-text-color);
}

.color-heat{
  color: var(--color-heat);
}
.fs15{
  font-size: 15px;
}
.fs16{
  font-size: 16px;
}
.fs18{
  font-size: 18px;
  font-weight: bold;
}
.fs18 span{
  font-size: 18px;
  font-weight: bold;
}
.fs20{
  font-size: 20px;
  font-weight: bold;
}
.fs24{
  font-size: 24px;
  font-weight: bold;
}
.fs25{
  font-size: 25px;
  font-weight: bold;
}
.fs26{
  font-size: 26px;
  font-weight: bold;
}
.fs28{
  font-size: 28px;
  font-weight: bold;
}
.fs75{
  font-size: 75px;
  font-weight: bold;
}
.fs80{
  font-size: 80px;
  font-weight: bold;
}
.fs30{
  font-size: 30px;
  font-weight: bold;
  color: var(--gray-btn);
}
.fs32{
  font-size: 32px;
}
.m0a{
  margin: 0 auto;
}
.m02{
  margin: 0 2px;
}

.mt1 {
  margin-top: 1px;
}
.mt2 {
  margin-top: 2px;
}
.mt3 {
  margin-top: 3px;
}
.mt4 {
  margin-top: 4px;
}
.mt5 {
  margin-top: 5px;
}
.mt6 {
  margin-top: 6px;
}
.mt7 {
  margin-top: 7px;
}
.mt8 {
  margin-top: 8px;
}
.mt9 {
  margin-top: 9px;
}
.mt10 {
  margin-top: 10px;
}
.mt11 {
  margin-top: 11px;
}
.mt12 {
  margin-top: 12px;
}
.mt13 {
  margin-top: 13px;
}
.mt14 {
  margin-top: 14px;
}
.mt15 {
  margin-top: 15px;
}
.mt16 {
  margin-top: 16px;
}
.mt17 {
  margin-top: 17px;
}
.mt18 {
  margin-top: 18px;
}
.mt19 {
  margin-top: 19px;
}
.mt20 {
  margin-top: 20px;
}
.mt21 {
  margin-top: 21px;
}
.mt22 {
  margin-top: 22px;
}
.mt23 {
  margin-top: 23px;
}
.mt24 {
  margin-top: 24px;
}
.mt25 {
  margin-top: 25px;
}
.mt26 {
  margin-top: 26px;
}
.mt27 {
  margin-top: 27px;
}
.mt28 {
  margin-top: 28px;
}
.mt29 {
  margin-top: 29px;
}
.mt30 {
  margin-top: 30px;
}

.mtb5{
  margin: 5px 0;
}
.mtb10{
  margin: 10px 0;
}
.mtb15{
  margin: 15px 0;
}
.mtb20{
  margin: 20px 0;
}
.mtb30{
  margin: 30px 0;
}
.mt40{
  margin-top: 40px;
}
.mt45{
  margin-top: 45px;
}
.mt60{
  margin-top: 60px;
}
.mt70{
  margin-top: 70px;
}

.mr1 {
  margin-right: 1px;
}
.mr2 {
  margin-right: 2px;
}
.mr3 {
  margin-right: 3px;
}
.mr4 {
  margin-right: 4px;
}
.mr5 {
  margin-right: 5px;
}
.mr6 {
  margin-right: 6px;
}
.mr7 {
  margin-right: 7px;
}
.mr8 {
  margin-right: 8px;
}
.mr9 {
  margin-right: 9px;
}
.mr10 {
  margin-right: 10px;
}
.mr11 {
  margin-right: 11px;
}
.mr12 {
  margin-right: 12px;
}
.mr13 {
  margin-right: 13px;
}
.mr14 {
  margin-right: 14px;
}
.mr15 {
  margin-right: 15px;
}
.mr16 {
  margin-right: 16px;
}
.mr17 {
  margin-right: 17px;
}
.mr18 {
  margin-right: 18px;
}
.mr19 {
  margin-right: 19px;
}
.mr20 {
  margin-right: 20px;
}
.mr21 {
  margin-right: 21px;
}
.mr22 {
  margin-right: 22px;
}
.mr23 {
  margin-right: 23px;
}
.mr24 {
  margin-right: 24px;
}
.mr25 {
  margin-right: 25px;
}
.mr26 {
  margin-right: 26px;
}
.mr27 {
  margin-right: 27px;
}
.mr28 {
  margin-right: 28px;
}
.mr29 {
  margin-right: 29px;
}
.mr30{
  margin-right: 30px;
}

.mr40{
  margin-right: 40px;
}

.mb1 {
  margin-bottom: 1px;
}
.mb2 {
  margin-bottom: 2px;
}
.mb3 {
  margin-bottom: 3px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb7 {
  margin-bottom: 7px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb9 {
  margin-bottom: 9px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb11 {
  margin-bottom: 11px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb13 {
  margin-bottom: 13px;
}
.mb14 {
  margin-bottom: 14px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb17 {
  margin-bottom: 17px;
}
.mb18 {
  margin-bottom: 18px;
}
.mb19 {
  margin-bottom: 19px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb21 {
  margin-bottom: 21px;
}
.mb22 {
  margin-bottom: 22px;
}
.mb23 {
  margin-bottom: 23px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb26 {
  margin-bottom: 26px;
}
.mb27 {
  margin-bottom: 27px;
}
.mb28 {
  margin-bottom: 28px;
}
.mb29 {
  margin-bottom: 29px;
}
.mb30 {
  margin-bottom: 30px;
}

.mb40{
  margin-bottom: 40px;
}

.ml1 {
  margin-left: 1px;
}
.ml2 {
  margin-left: 2px;
}
.ml3 {
  margin-left: 3px;
}
.ml4 {
  margin-left: 4px;
}
.ml5 {
  margin-left: 5px;
}
.ml6 {
  margin-left: 6px;
}
.ml7 {
  margin-left: 7px;
}
.ml8 {
  margin-left: 8px;
}
.ml9 {
  margin-left: 9px;
}
.ml10 {
  margin-left: 10px;
}
.ml11 {
  margin-left: 11px;
}
.ml12 {
  margin-left: 12px;
}
.ml13 {
  margin-left: 13px;
}
.ml14 {
  margin-left: 14px;
}
.ml15 {
  margin-left: 15px;
}
.ml16 {
  margin-left: 16px;
}
.ml17 {
  margin-left: 17px;
}
.ml18 {
  margin-left: 18px;
}
.ml19 {
  margin-left: 19px;
}
.ml20 {
  margin-left: 20px;
}
.ml21 {
  margin-left: 21px;
}
.ml22 {
  margin-left: 22px;
}
.ml23 {
  margin-left: 23px;
}
.ml24 {
  margin-left: 24px;
}
.ml25 {
  margin-left: 25px;
}
.ml26 {
  margin-left: 26px;
}
.ml27 {
  margin-left: 27px;
}
.ml28 {
  margin-left: 28px;
}
.ml29 {
  margin-left: 29px;
}
.ml30 {
  margin-left: 30px;
}

.ml40{
  margin-left: 40px;
}
.mlr15{
  margin: 0 15px;
}
.wfc{
  width: fit-content;
}
.w50p{
  width: 50%;
}
.w100p{
  width: 100%;
}
.h100p{
  height: 100%;
}
.w200{
  width: 200px;
}
.mw100 {
    max-width: 100px;
}
.minw10 {
  min-width: 10px;
}
.op4{
  opacity: 0.4;
}
.op6{
  opacity: 0.6;
}
.tll{
  text-align: left;
}
.tlc{
  text-align: center;
}
.tlr{
  text-align: right;
}
.ellipse {
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.tdu {
  text-decoration: underline;
}
.ant-input{
  border-color: var(--gray-border-color);
}
.ant-divider-horizontal.ant-divider-with-text::after,.ant-divider-horizontal.ant-divider-with-text::before{
  border-block-start-color:var(--gray-border-color)
}
.fulltab.ant-tabs .ant-tabs-nav-wrap .ant-tabs-nav-list{
    width: 100%;
    flex: 1;
    border-bottom: 1px solid #9999;
    margin-bottom: 1px;
}
.fulltab.ant-tabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab{
    flex: 1;
    justify-content: center;
}
.fulltab.ant-tabs .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab+.ant-tabs-tab {
    margin-left: 0;
}
.fulltab.ant-tabs .ant-tabs-tab.ant-tabs-tab-active::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 3px;
    background: var(--primary-gradient);
}

.fulltab.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-decoration: none !important;
}

.tptabs.ant-tabs >.ant-tabs-nav .ant-tabs-nav-operations{
  display: none;
}
.tptabs.ant-tabs >.ant-tabs-nav .ant-tabs-nav-list{
  width: 100%;
}
.tptabs.ant-tabs .ant-tabs-tab {
  flex: 1;
}
.tptabs.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn{
  font-size: 14px;
  width: 100%;
}

.tptabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 14px;
  /* text-decoration: underline; */
  color: var(--primary-text-color);
  background: var(--TP-blue);
  border: none;

}
.tptabs.activeSizeChange.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 18px;
}


.tptabs.ant-tabs-top >.ant-tabs-nav::before{
  border: none;
}
.tptabs.ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar{
  display: none;
}
.tptabs.ant-tabs-top >.ant-tabs-nav{
  margin-bottom: 0;
}
.tptabs.ant-tabs .ant-tabs-tab+.ant-tabs-tab{
  margin-left: 15px;
}
.tptabs.ant-tabs{
  height: 100%;
  width: 100%;
}
.tptabs.ant-tabs .ant-tabs-content-holder{
  overflow-y: auto;
}
.ant-modal .ant-modal-content{
  padding: 20px;
  background-color: var(--black);
  border: 1px solid var(--dark-border);
  position: relative;
}
.leadBoardTabs.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn{
  width: 90px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;

}

/* .ant-modal .ant-modal-confirm-btns button span{
  color: var(--title);
} */
.avatarInfo .ant-avatar.ant-avatar-square{
  border-radius: 50%;
  border: 1px solid var(--white);
}
.avatarInfo.noBorder .ant-avatar.ant-avatar-square{
  border: none;
}
.ant-spin-nested-loading >div>.ant-spin.loadPageSpin{
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ant-spin-nested-loading >div>.ant-spin.loadPageSpin .ant-spin-dot{
  position: relative;
  top: 0;
  inset-inline-start:0;
  margin: 0;
}
.ant-spin-nested-loading >div>.ant-spin.loadPageSpin .ant-spin-text{
  position: relative;
  font-size: 20px;
  color: var(--title);
  top: 0;
  font-family: var(--font-moment) !important;
}
.ant-spin-nested-loading{
  height: 100%;
}
.loadImgSpinWrapper.ant-spin-nested-loading{
  width: 100%;
}
.loadPageSpin {
  width: 100%;
}
.loadPageSpin svg{
    width: 100%;
    height: 100%;

}
.loadPageSpin svg path{
  color: var(--a);
}
/* .radioGroup .ant-radio-button-wrapper-checked span {
  color: var(--white);
} */
.ant-tabs.tptabs > .ant-tabs-nav .ant-tabs-tab-btn{
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.15);
  padding: 0px 0px;
  border-radius: 5px;
  height: 29px;
  /* line-height: 29px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
/* .ant-tabs.tptabs .ant-tabs-tab.ant-tabs-tab-active{ */
  /* background-image: url(./assets/images/UI_TT_Picture_Xian.png); */
  /* background-position: center 35px; */
  /* background-size: 20px 3px; */
  /* background-repeat: no-repeat; */
  /* border-bottom: 3px solid var(--a) */
/* } */
/*
.ant-radio-group.radioGroup{
  border-radius: 20px;
  background-color: var(--white);
  margin: 0 auto;
  border: none;
} */

/* .ant-radio-group.radioGroup .ant-radio-button-wrapper:first-child{
  border-start-start-radius: 20px;
  border-end-start-radius: 20px;
}
.ant-radio-group.radioGroup  .ant-radio-button-wrapper:last-child{
  border-start-end-radius: 20px;
  border-end-end-radius: 20px;
} */
/* .ant-radio-group.radioGroup .ant-radio-button-wrapper{
  text-align: center;
  border: none;
} */
.ant-radio-group .ant-radio-button-wrapper span{
  color: var(--btn-color-black);
  border: none;
}
.ant-radio-group .ant-radio-button-wrapper-checked span{
  color: var(--content);
  border: none;
}
/* .ant-radio-group .ant-radio-button-wrapper span{
  color: var(--btn-color-black);
  font-weight: bold;
  border: none;
}

.ant-radio-group .ant-radio-button-wrapper-checked span{
  color: var(--white);
}


 */

.ant-modal-confirm-title {
  font-weight: normal !important;
}
.liveModal.ant-modal-confirm span{
  font-family: var(--font-moment) !important;
}

.liveModal.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-title,.liveModal.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-content{
  color: var(--content);
  font-family: var(--font-moment) !important;
}
.liveModal.ant-modal-confirm .ant-btn-default span{
  color: var(--btn-color-black);
  padding-top: 2px;
}
.ant-btn-primary{
  background-color: var(--btn-active-bg);
  color: var(--content);
}
.ant-message .ant-message-notice .ant-message-notice-content{
  background-color: var(--content);
}
.ant-message .ant-message-notice .ant-message-notice-content span{
  color: var(--btn-color-black);
}
.ant-message .ant-message-notice .ant-message-notice-content path{
  color: var(--btn-color-black);
}
.ant-message{
  z-index: 9999;
}
.ant-tooltip .ant-tooltip-content{
  background-color: var(--white);
  border-radius: 4px;
}
.ant-tooltip .ant-tooltip-inner{
  background-color: transparent;
  color: #000;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
}
.ant-tooltip .ant-tooltip-arrow::before{
    background-color: var(--white);
}
.liveModal .ant-btn-primary{
  background-color: var(--TP-blue);
}
.liveModal .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active,.liveModal .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover{
  background-color: var(--TP-blue);
}
.ant-dropdown-menu-title-content div{
  color: var(--white);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item{
  width: 50px !important;
}
.ant-dropdown .ant-dropdown-menu{
  background-color: #3F4142;
}
.ant-dropdown{
  width: 50px !important;
  min-width: 60px !important;
}
.red-packet-dropdown{
  width: 70px !important;
  min-width: 70px !important;
}
.ant-dropdown .ant-dropdown-arrow:before{
  background-color: #3F4142;
}
.btn_public,.btn_public:focus-visible{
  height: auto;
  outline: none !important;
  font-size: 12px;
  width: 100%;
  background: var(--TP-yellow);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_public span,.btn_public:focus-visible span{
  color: var(--black);
  font-weight: bold;
}
.btn_public.widthAuto, .btn_public.widthAuto:focus-visible{
  width: auto;
}



.btn_public span,.btn_public:focus-visible span{
  color: var(--black);
}

.btn_green,.btn_green:focus-visible{
  height: auto;
  outline: none !important;
  font-size: 16px;
  width: 100%;
  background: var(--green-gradient);
  border: none;
  display: flex;
}

.btn_blue,.btn_blue:focus-visible{
  height: auto;
  outline: none !important;
  font-size: 12px;
  width: 100%;
  background: var(--TP-blue);
  border: none;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_blue_light,.btn_blue_light:focus-visible{
  height: auto;
  outline: none !important;
  font-size: 12px;
  width: 100%;
  background: var(--TP-blue-light);
  border: none;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn.ant-btn-loading.opaque{
  opacity: 1;
}
.btn_gray,.btn_gray:focus-visible{
  height: auto;
  outline: none !important;
  font-size: 16px;
  width: 100%;
  background: var(--bg-white-5);
  border: var(--border);

}
.btn_gray span{
  color: var(--black);
}

.btn_dark,.btn_dark:focus-visible{
  height: auto;
  outline: none !important;
  font-size: 16px;
  width: 100%;
  background: #272727;
  border: none;

}
.btn_dark span{
  color: var(--white);
}


.btn_white,.btn_white:focus-visible{
  height: auto;
  outline: none !important;
  border: none;
  font-size: 16px;
  width: 100%;
  background: var(--white);

}
.btn_white span{
  color: var(--black);
  font-weight: bold;
}
.btn_yellow,.btn_yellow:focus-visible{
  height: auto;
  outline: none !important;
  border: none;
  font-size: 16px;
  width: 100%;
  background: var(--TP-yellow);

}
.btn_yellow span{
  color: var(--black);
  font-weight: bold;
}
.ant-progress-text{
  font-size: 30px !important;
  color: var(--white)!important;
  font-weight: bold;
}
.btn_transparent,.btn_transparent:focus-visible{
  outline: none !important;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  background: transparent;
}
.btn_transparent_moment,.btn_transparent:focus-visible{
  outline: none !important;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  background: rgba(0, 0, 0, .32);
}


.btn_black,.btn_black:focus-visible{
  outline: none !important;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  background: #000;
  border: 1px solid #EEEEEE;
}
.btn_disabled,.btn_disabled:focus-visible{
  height: auto;
  border: none;
  outline: none !important;
  font-size: 16px;
  width: 100%;
  background: var(--btn-disable-bg-color);

  color: var(--btn-disable);

}
.ant-btn-default.btn_disabled:disabled{
  background-color: var(--border-gray);
}
.btn_disabled span{
  color: var(--black);
}
.btnVoteControl{
  width: 100%;
}
.wrapWhole{
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.content{
  padding: 20px;
  width: 100%;
  overflow-y: scroll;
}
.abs{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  /*background-image: url(./assets/images/publicBg.png);*/
  background-size: cover;
  background-position: center;
}
.inputNumber{
  flex: 1;
  border-radius: 30px;
  background-color: var(--gray-bg);
  border: none;
  text-align: center;
}
.ant-modal.transModal .ant-modal-content{
  background-color: var(--black);
  border: 1px solid var(--border-gray);
}
.mainWrap{
  /* height: 100%; */
  height: calc( 100% -  68px - (env(safe-area-inset-bottom)/2)) !important;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.mainMemeDetailWrap{
  /* height: 100%; */
  height: calc( 100% - 114px - (env(safe-area-inset-bottom)/2)) !important;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.ant-modal-root{
  width: 0;
}
.liveSetting{
  width: 100%;
}
.ant-select.liveSetting .ant-select-selector{
  background-color: rgba(255,255,255,0.2);
  border-color: rgba(0,0,0,0);
}
.ant-select.liveSetting.wish .ant-select-selector{
  background-color: var(--TP-blue-light);
  border-color: #cccccc;
  border-radius: 10px;
}

.ant-select.liveSetting .ant-select-selector .ant-select-selection-item{
  color: var(--white);
}
.ant-select.liveSetting.wish .ant-select-selector .ant-select-selection-item{
  text-align: left;
  display: flex;
  align-items: center;
}
.liveSettingOption.ant-select-dropdown .ant-select-item-option-content{
  display: flex;
  align-items: center;
  font-family: var(--font-moment) !important;
}
.liveSettingOption.ant-select-dropdown .ant-select-item-group{
  color: #ffffff;
}

/**/
.momentLiveSetting{
  width: 302px;
  height: 28px;
}
.ant-select.momentLiveSetting .ant-select-selector{
  background-color: rgba(0,0,0,0.30);
  border-color: rgba(0,0,0,0);
  border-radius: 4px;
}
.ant-select.momentLiveSetting.wish .ant-select-selector{
  background-color: var(--TP-blue-light);
  border-color: #cccccc;
  border-radius: 10px;
}

.ant-select.momentLiveSetting .ant-select-selector .ant-select-selection-item{
  color: var(--TP-moment-gray);
  font-size: 12px;
}
.ant-select.momentLiveSetting.wish .ant-select-selector .ant-select-selection-item{
  text-align: left;
  display: flex;
  align-items: center;
}
.momentLiveSettingOption.ant-select-dropdown .ant-select-item-option-content{
  display: flex;
  align-items: center;
  font-family: var(--font-moment) !important;
}
.momentLiveSettingOption.ant-select-dropdown .ant-select-item-group{
  color: #ffffff;
}

.radioGroup .ant-radio-button-wrapper{
  background-color: rgba(115,193,228, 0.95);
  border-color: rgba(0,0,0,0);

  span {
    color: var(--white);
    font-weight: normal;
  }
}

.radioGroup .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  background-color: var(--TP-yellow);
  border-color: var(--TP-yellow);

  span {
    color: var(--black);
  }
}
.liveSettingOption.ant-select-dropdown,.liveSettingOption.ant-select-dropdown .ant-select-item,.liveSettingOption.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--bg-black-5);
  /* border-color: #453131; */

}
.liveSettingOption.ant-select-dropdown .ant-select-item{
  border-bottom: 1px solid #6D6B73;
}
.liveSettingOption.ant-select-dropdown .ant-select-item:last-child{
  border: none;
}

/**/
.momentLiveSettingOption.ant-select-dropdown,.momentLiveSettingOption.ant-select-dropdown .ant-select-item,.liveSettingOption.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--bg-black-5);
  /* border-color: #453131; */

}
.momentLiveSettingOption.ant-select-dropdown .ant-select-item{
  border-bottom: 1px solid #6D6B73;
}
.momentLiveSettingOption.ant-select-dropdown .ant-select-item:last-child{
  border: none;
}

.ant-tooltip{
  z-index: 3100;
}
.ant-tooltip.customTooltip{
  z-index: 100;
}
.tptabs.ant-tabs .ant-tabs-content,
.tptabs.ant-tabs .ant-tabs-content-holder,
.tptabs.ant-tabs .ant-tabs-content,
.tptabs.ant-tabs .ant-tabs-tabpane
{
  height: 100%;
}
.tptabs.tpPlr15 .ant-tabs-nav{
  padding: 0 15px;
}
.tabNav20.ant-tabs .ant-tabs-tab {
    margin-left: 20px;
}
.pointsTabs{
    margin: 5%;
    border-radius: 30px;
    border: 1px solid #8889;
    background: #13020499;
    overflow: hidden;
}
.pointsTabs.noborder{
  border: none;
  margin: 0;
  border-radius: 0;
  width: 100%;
  background-color: transparent;
}
.pointsTabs.ant-tabs >.ant-tabs-nav .ant-tabs-nav-list{
  width: 100%;
  display: flex;
}
.pointsTabs.ant-tabs .ant-tabs-tab{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointsTabs.ant-tabs-top >.ant-tabs-nav::before{
  border-bottom: 2px solid #444;
}
.pointsTabs.ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar{
  height: 2px;
}
.pointsTabs.ant-tabs .ant-tabs-ink-bar{
  background: var(--primary-gradient);
}
.pointsTabs.ant-tabs .ant-tabs-tab-btn{
  color: #aaa;
  font-size: 16px;
}
.pointsTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff;
}

.buyAndSellTabs{
  margin-top: 20px;
  overflow: hidden;
}
.buyAndSellTabs.noborder{
border: none;
margin: 0;
border-radius: 0;
width: 100%;
background-color: transparent;
}
.buyAndSellTabs.ant-tabs >.ant-tabs-nav .ant-tabs-nav-list{
width: 100%;
display: flex;
/* align-items: flex-start; */
}
.buyAndSellTabs.ant-tabs .ant-tabs-tab{
flex: 1;
display: flex;
align-items: center;
justify-content: center;
padding: 0;
}

.buyAndSellTabs.ant-tabs-top >.ant-tabs-nav::before{
  display: none;
}
.buyAndSellTabs.ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar{
  display: none;
}
.buyAndSellTabs.ant-tabs-top >.ant-tabs-nav{
  margin: 0;
}

.buyAndSellTabs.ant-tabs .ant-tabs-content{
  background: var(--bg-black-35);
  border-radius: 0 0 8px 8px;
}

.buyAndSellTabs.ant-tabs .ant-tabs-tab:nth-child(1) > .ant-tabs-content {
  border-radius: 0 8px 8px 8px;
}

.buyAndSellTabs.ant-tabs .ant-tabs-tab:nth-child(2) > .ant-tabs-content {
  border-radius: 8px 0 8px 8px;
}


.buyAndSellTabs.ant-tabs .ant-tabs-tab-btn{
  color: #fff;
  font-size: 14px;
  aspect-ratio: 175 / 31;
  display: flex;
  justify-content: center;
  align-content: center;
}
.buyAndSellTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: var(--TP-yellow);
  font-size: 18px;
  aspect-ratio: 179 / 29;
}

.buyAndSellTabs.ant-tabs .ant-tabs-tab:nth-child(1) .ant-tabs-tab-btn {
  background: url(../public/UI/Picture/UI_Picture_TabBar_Buy_02.png);
  background-size: cover;
  flex: 167;
  height: 80%;
}

.buyAndSellTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active:nth-child(1) .ant-tabs-tab-btn {
  background: url(../public/UI/Picture/UI_Picture_TabBar_Buy_01.png);
  background-size: cover;
  flex: 179;
  margin-right: -5px;
  height: 100%;
}

.buyAndSellTabs.ant-tabs .ant-tabs-tab:nth-child(2) .ant-tabs-tab-btn {
  background: url(../public/UI/Picture/UI_Picture_TabBar_Sell_02.png);
  background-size: cover;
  flex: 167;
  height: 80%;
}

.buyAndSellTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active:nth-child(2) .ant-tabs-tab-btn {
  background: url(../public/UI/Picture/UI_Picture_TabBar_Sell_01.png);
  background-size: cover;
  flex: 179;
  margin-left: -5px;
  height: 100%;
}

.buyAndSellTabs.ant-tabs .ant-tabs-tab+.ant-tabs-tab{
  margin-left: 0;
}




.orientationTip{
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 40px;
  font-weight: bold;
  background-color: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}



.customTabs {
  width: 100%;
  display: flex;
}

.ant-modal-root .ant-modal-wrap{
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-modal.confirmModalWrap .ant-modal-footer{
  display: none;
}
.ant-modal.confirmModalWrap .ant-modal-header{
  background-color: transparent;
  width: 100%;
  height: 95px;
  margin-bottom: 0px;
  display: flex;
  align-items: start;
  justify-content: center;
}
.ant-modal.confirmModalWrap .ant-modal-title{
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: var(--white);
}
.ant-modal.confirmModalWrap .ant-modal-close{
  top: 5px;
  inset-inline-end: 8px;
}

.ant-modal.confirmModalWrap .ant-modal-content {
  padding: 10px !important;
  border-radius: 0px;
  background-image: url(../public/UI/Picture/UI_Picture_Title-Blue_UP-Notification_01.png);
  background-size: 100% auto;
  background-position: left top;
  background-repeat: no-repeat;
  background-color: var(--model-bg-color);
  border: 2px solid var(--model-border-color);
}


.confirmModalWrap.ant-modal.noCloseBtn .ant-modal-close{
  display: none;
}


.confirmModalWrap.ant-modal .ant-modal-close .ant-modal-close-x{
  display: none;
}

.confirmModalWrap.ant-modal .ant-modal-close{
  width: 20px;
  height: 20px;
  background-image: url(../public/UI/Button/UI_Button_Close_01.png);
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
}

.ant-modal.confirmModalWrap.noImageTitle .ant-modal-content{
  background-image:none;
}

.ant-modal.confirmModalWrap.noImageTitle .ant-modal-header{
  height: 40px;
}

.ant-modal.confirmModalWrap.newModal .ant-modal-content {
    padding: 0 !important;
    border-color: rgba(255, 255, 255, .3)
}
.ant-modal.confirmModalWrap.fullModal,.ant-modal.confirmModalWrap.fullModal .ant-modal-content  {
    width: 100vw !important;
    height: calc(100vh - (env(safe-area-inset-bottom) * 2));
    max-width: 100vw;
    border: none;
    border-radius: 0;
}

/*moment*/
.ant-modal.momentModalWrap .ant-modal-footer{
  display: none;
}
.ant-modal.momentModalWrap .ant-modal-header{
  background-color: transparent;
  width: 100%;
  height: 95px;
  margin-bottom: 0px;
  display: flex;
  align-items: start;
  justify-content: center;
}
.ant-modal.momentModalWrap .ant-modal-title{
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: var(--white);
}
.ant-modal.momentModalWrap .ant-modal-close{
  top: 5px;
  inset-inline-end: 8px;
}

.ant-modal.momentModalWrap .ant-modal-content {
  padding: 10px !important;
  background: #1B1D28;
  border-radius: 8px;
  border: 0px solid;
  margin-bottom: 30px;
}

.ant-modal.momentModalWrap.noBg .ant-modal-content {
  padding: 10px !important;
  background: rgba(0,0,0,0);
  border-radius: 8px;
  border: 0px solid;
  margin-bottom: 30px;
}

.ant-modal.momentModalWrap.noPaddingModal .ant-modal-content {
  padding: 0px !important;
}

.ant-modal.momentModalWrapLight .ant-modal-content {
  background: #333333 !important;
}

.momentModalWrap.ant-modal.noCloseBtn .ant-modal-close{
  display: none;
}


.momentModalWrap.ant-modal .ant-modal-close .ant-modal-close-x{
  display: none;
}

/*.momentModalWrap.ant-modal .ant-modal-close{
  width: 20px;
  height: 20px;
  background-image: url(../public/UI/Button/UI_Button_Close_01.png);
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
}*/

.ant-modal.momentModalWrap.noImageTitle .ant-modal-content{
  background-image:none;
}

.ant-modal.momentModalWrap.noImageTitle .ant-modal-header{
  height: 40px;
}

.ant-modal.momentModalWrap.newModal .ant-modal-content {
    padding: 0 !important;
    border-color: rgba(255, 255, 255, .3)
}
.ant-modal.momentModalWrap.fullModal,.ant-modal.momentModalWrap.fullModal .ant-modal-content  {
    width: 100vw !important;
    height: calc(100vh - (env(safe-area-inset-bottom) * 2));
    max-width: 100vw;
    border: none;
    border-radius: 0;
}

/*meme*/
.ant-modal.memeNftModalWrap .ant-modal-footer{
  display: none;
}
.ant-modal.memeNftModalWrap .ant-modal-header{
  background-color: transparent;
  width: 100%;
  height: 0;
  margin-bottom: 0px;
  display: flex;
  align-items: start;
  justify-content: center;
}
.ant-modal.memeNftModalWrap .ant-modal-title{
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: var(--white);
}
.ant-modal.memeNftModalWrap .ant-modal-close{
  top: 5px;
  inset-inline-end: 8px;
}

.ant-modal.memeNftModalWrap .ant-modal-content {
  padding: 10px !important;
  border-radius: 16px;
  background-color: var(--TP-blue);
  border: 2px solid rgba(255,255,255,1);
}


.memeNftModalWrap.ant-modal.noCloseBtn .ant-modal-close{
  display: none;
}


.memeNftModalWrap.ant-modal .ant-modal-close .ant-modal-close-x{
  display: none;
}

.memeNftModalWrap.ant-modal .ant-modal-close{
  width: 20px;
  height: 20px;
  background-image: url(../public/UI/Button/UI_Button_Close_01.png);
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
}

.ant-modal.memeNftModalWrap.noImageTitle .ant-modal-content{
  background-image:none;
}

.ant-modal.memeNftModalWrap.noImageTitle .ant-modal-header{
  height: 40px;
}

.ant-modal.memeNftModalWrap.newModal .ant-modal-content {
    padding: 0 !important;
    border-color: rgba(255, 255, 255, .3)
}
.ant-modal.memeNftModalWrap.fullModal,.ant-modal.memeNftModalWrap.fullModal .ant-modal-content  {
    width: 100vw !important;
    height: calc(100vh - (env(safe-area-inset-bottom) * 2));
    max-width: 100vw;
    border: none;
    border-radius: 0;
}
.ant-modal.memeNftModalWrap.p0 .ant-modal-content{
  padding: 0 !important;
}

.ant-modal.memeNftModalWrap.redpacket .ant-modal-content{
  padding: 0 !important;
  background-image: url(./assets/images/Redpacket_bg.png);
  border: none;
  background-color: rgba(0,0,0,0);
  border-radius: 15px;
}

.ant-modal.memeNftModalWrap.p1 .ant-modal-content{
  padding: 0px;
  background-color: rgba(0,0,0,0);
  border: 0px solid var(--border);
}

.whiteInput{
  color: var(--white);
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  font-family: 'ProtestStrikeLight', 'ProtestStrikeRegular', 'Roboto', sans-serif;
  /* font-weight: bold; */
}

.avatarInfo .ant-image{
  width: 100%;
}
.upperCaseFirstLetter::first-letter {
    text-transform: uppercase
}
.df{
  display: flex;
}
.jc-spaceBetween{
  justify-content: space-between;
}
.jcsa{
  justify-content: space-around;
}
.jcfe{
  justify-content: flex-end;
}
.ais{
  align-items: start;
}
.aic{
  align-items: center;
}
.aib{
  align-items: baseline;
}
.fdc{
  flex-direction: column;
}
.f1{
    flex: 1;
  }
.textnowrap {
    white-space: nowrap;
}
.hireSwiper.border-bottomn {
    border-bottom: 1px solid var(--color-gray);
}
.fullModal .hireSwiper .swiper-slide{
    height: calc(100vh - (env(safe-area-inset-bottom) * 2));
}
.hireSwiper .swiper-slide{
    transition: 300ms;
    /* transform: scale(0.8); */
    scale: 0.9;
    width: 70%;
    height: 580px;
}
.swiper-slide-active .card {
    border: 1px solid #c5a158;
    box-shadow: 0 0 20px  #c5a158;
    background-color: #000;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
}
.swiper-slide-active .card.needMask:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0) 15%,
        rgba(0, 0, 0, 0) 85%,
        rgba(0, 0, 0, 1) 100%
        );

}
.hireSwiper .swiper-slide-active{
  scale: 1;
  filter: none ;
}

.swiper-button-prev {
    position: absolute;
    left: -12px;
    top: 50%;
}
.hireDropDown {
    position: relative;
    z-index: 11;
    height: 30px;
    width: 310px;
    background-color: rgba(0,0,0,.7);
    border: 1px solid rgba(255, 255, 255, .3);
}
.hireDropDownMenu.ant-dropdown {
    width: 330px !important;
    z-index: 4000;
}
.hireDropDownMenu.ant-dropdown ul {
    width: 310px;
    max-height: 200px;
    overflow: auto;
    border-radius: 8px
}
.hireDropDownMenu.ant-dropdown ul::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(255, 255, 255, .3);
}
.hireDropDownMenu.ant-dropdown ul::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: rgba(255, 255, 255, .7);
    border-radius: 50%;
}
.hireDropDownMenu.ant-dropdown .ant-dropdown-menu {
    background-color: rgba(0,0,0,.7);
}
.hireDropDownMenu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    width: 100% !important;
    padding: 2px;
}
.hireDropDownMenu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .default-item {
    padding: 0 4px;
}
.hireDropDownMenu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .current-item {
    display: inline-block;
    width: 100%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .5);
    padding: 0 4px;
}

.nftHistoryDropDown {
  position: relative;
  z-index: 11;
  height: 30px;
  width: 150px;
  background-color: rgba(0,0,0,.7);
  border: 1px solid rgba(255, 255, 255, .3);
}
.nftHistoryDropDownMenu.ant-dropdown {
  width: 150px !important;
  z-index: 4000;
}
.nftHistoryDropDownMenu.ant-dropdown ul {
  width: 150px;
  max-height: 200px;
  overflow: auto;
  border-radius: 8px
}
.nftHistoryDropDownMenu.ant-dropdown ul::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(255, 255, 255, .3);
}
.nftHistoryDropDownMenu.ant-dropdown ul::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: rgba(255, 255, 255, .7);
  border-radius: 50%;
}
.nftHistoryDropDownMenu.ant-dropdown .ant-dropdown-menu {
  background-color: rgba(0,0,0,.7);
}
.nftHistoryDropDownMenu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  width: 100% !important;
  padding: 2px;
}
.nftHistoryDropDownMenu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .default-item {
  padding: 0 4px;
}
.nftHistoryDropDownMenu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .current-item {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, .5);
  padding: 0 4px;
}
.momentCheckBoxGroup.ant-checkbox-group{
  row-gap: 12px;
  flex-direction: column;
}

.momentCheckBox .ant-checkbox .ant-checkbox-inner{
  border-radius: 50% !important;
  background-color: var(--bg-black-35) !important;
  border-color: var(--bg-white) !important;
}

.momentCheckBox .ant-checkbox-checked:after{
  border-radius: 50% !important;
  border-color: var(--bg-white-35) !important;
}

.momentCheckBox .ant-checkbox-checked .ant-checkbox-inner{
  border-radius: 50% !important;
  background-color: var(--white) !important;
  border-color: var(--bg-white-35) !important;
}

.momentCheckBox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: black !important;
}


.momentFilterSlider.ant-slider .ant-slider-rail{
  background-color: #333;
  border: 1px solid #999;
}
.momentFilterSlider.ant-slider .ant-slider-track{
  position: absolute;
  top: 3px;
  height: 7px;
  border-radius: 7px;
  background: var(--white);
}
.momentFilterSlider.ant-slider-horizontal .ant-slider-rail{
  height: 7px;
  border-radius: 7px;
  top: 3px;
}
.momentFilterSlider.ant-slider .ant-slider-handle::after{
  width: 11px;
  height: 11px;
  background-color: #D8D8D8;
  box-shadow: 0 0 0 2px #D8D8D8;
}



.hireCheckBox .ant-checkbox .ant-checkbox-inner{
  background: var(--bg-black-5);
  border-radius: 50%;
  border:1px solid var(--white);
  width: 18px;
  height: 18px;
}
.hireCheckBox .ant-radio .ant-radio-inner{
  background: var(--bg-black-5);
  border-radius: 50%;
  border:1px solid var(--white);
  width: 18px;
  height: 18px;
}
.hireCheckBox.ant-checkbox-wrapper-disabled .ant-checkbox .ant-checkbox-inner{
  background-color: var(--white);
}
.hireCheckBox.ant-radio-wrapper-disabled .ant-radio .ant-radio-inner{
  background-color: var(--white);
}
.hireCheckBox .ant-checkbox-checked .ant-checkbox-inner:after{
  display: none;
  width: 0;
  height: 0;
  transform: none;
  transition: none;
}
.hireCheckBox .ant-radio-checked .ant-radio-inner:after{
  display: none;
  width: 0;
  height: 0;
  transform: none;
  transition: none;
}
.hireCheckBox .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
  border-color: var(--white);
}
.hireCheckBox .ant-radio:not(.ant-radio-disabled):hover .ant-radio-inner{
  border-color: var(--white);
}
.hireCheckBox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner{
  background: rgba(255,218,10,1);
  border-color: rgba(255,218,10,1);
  border-width: 2px;
}
.hireCheckBox.ant-radio-wrapper:not(.ant-radio-wrapper-disabled):hover .ant-radio-checked:not(.ant-radio-disabled) .ant-radio-inner{
  background: rgba(255,218,10,1);
  border-color: rgba(255,218,10,1);
  border-width: 2px;
}
.hireCheckBox .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background: rgba(255,218,10,1);
  border-color: rgba(255,218,10,1);
  border-width: 2px;
}
.hireCheckBox .ant-radio.ant-radio-checked .ant-radio-inner {
  background: var(--TP-yellow);
  border-color: var(--TP-yellow);
  border-width: 2px;
}
.hireCheckBox .ant-checkbox-checked:after{
  border: none;
}
.hireCheckBox .ant-radio-checked:after{
  border: none;
}
.hireSlider.ant-slider .ant-slider-rail{
  background-color: var(--gray-bg);
}
.hireSlider.ant-slider .ant-slider-track{
  position: absolute;
  top: 4px;
  height: 8px;
  border-radius: 8px;
  background: var(--primary-gradient);
}
.hireSlider.ant-slider-horizontal .ant-slider-rail{
  height: 8px;
  border-radius: 8px;
  top: 4px;
}
/* .hireSlider.ant-slider-horizontal{
  padding-block: 6px;
} */
.hireSlider.ant-slider .ant-slider-handle::before{
  width: 17px;
  height: 17px;
}
.hireSlider.ant-slider .ant-slider-handle::after{
  width: 13px;
  height: 13px;
  box-shadow: 0 0 0 2px #fff;
}
.hireSlider.ant-slider .ant-slider-handle{
  width: 13px;
  height: 13px;
}
.hireSlider.ant-slider-horizontal .ant-slider-step{
  top: 4px;
  height: 8px;
}
.infinite-scroll-component__outerdiv{
  width: 100%;
}
.hirePost.ant-btn >span+.anticon,.hirePost.ant-btn >.anticon+span {
  margin-inline-start:2px
}
.hireSlider.ant-slider{
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}
.hireSlider .ant-slider-mark .ant-slider-mark-text {
  color: rgb(255, 255, 255, .5);
  margin-top: 10px;
  font-size: 12px;
}

.staticSlider.ant-slider {
    margin: 0 auto;
    height: 18px;
}
.staticSlider.hireSlider.ant-slider .ant-slider-rail {
    background: rgba(195, 195, 195, 0.27) !important;
    height: 18px;
    top: 0;
}
.staticSlider.hireSlider.ant-slider .ant-slider-track {
    height: 18px;
    top: 0;
}
.staticSlider.hireSlider.ant-slider .ant-slider-handle::after {
    display: none;
}
.wishBG{
  background-image: url(./assets/images/bgImg/wishBG.webp);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.ant-modal.confirmModalWrap.p0 .ant-modal-content{
  padding: 0 !important;
}

.ant-modal.confirmModalWrap.redpacket .ant-modal-content{
  padding: 0 !important;
  background-image: url(./assets/images/Redpacket_bg.png);
  border: none;
  background-color: rgba(0,0,0,0);
  border-radius: 15px;
}

.ant-modal.confirmModalWrap.p1 .ant-modal-content{
  padding: 0px;
  background-color: rgba(0,0,0,0);
  border: 0px solid var(--border);
}

.alc{
  align-items: center;
}
.hireFilter.ant-drawer-content{
  background: var(--model-bg-color);
  height: auto;
  position: absolute;
  top: 140px;
  border-radius: 0px 0 0 0px;
  border:  2px solid var(--model-border-color);
}

.gasFilter.ant-drawer-content{
  top: 50%;
}
.momentGasFilter.ant-drawer-content{
  top: 43%;
  background: #1B1D28;
  height: auto;
  position: absolute;
  border-radius: 8px;
  right: 10px;
}
.ant-drawer .momentGasFilter .ant-drawer-header{
  display: none;
}
.momentGasFilter .ant-drawer-body{
  padding: 10px;
}

.ant-drawer-content .ant-drawer-wrapper-body{
  height: auto;
}
.ant-drawer .hireFilter .ant-drawer-header{
  display: none;
}
.ant-drawer .hireFilter .ant-drawer-body{
  padding: 20px;
  background-image: url(../public/UI/Picture/UI_Picture_Title-Blue_UP-Notification_01.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.hireSort.ant-drawer-content{
  background: var(--model-bg-color);
  height: auto;
  position: absolute;
  top: 140px;
  border-radius: 0px 0 0 0px;
  border:  2px solid var(--model-border-color);
}
.ant-drawer-content .ant-drawer-wrapper-body{
  height: auto;
}
.ant-drawer .hireSort .ant-drawer-header{
  display: none;
}
.ant-drawer .hireSort .ant-drawer-body{
  padding: 10px 25px 25px 20px;
  background-image: url(../public/UI/Picture/UI_Picture_Title-Blue_UP-Notification_02.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.momentSort.ant-drawer-content{
  background: #1B1D28;
  border-radius: 8px;
  height: auto;
  position: absolute;
  top: 140px;
  right: 8px;
}
.ant-drawer .momentSort .ant-drawer-header{
  display: none;
}
.ant-drawer .momentSort .ant-drawer-body{
  padding: 10px 20px 25px 20px;
  /*background-image: url(../public/UI/Picture/UI_Picture_Title-Blue_UP-Notification_02.png);*/
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.ant-drawer-right>.ant-drawer-content-wrapper{
  box-shadow: none;
}
.ant-drawer{
  width: 100%;
  max-width: 500px;
  left: 50%;
  transform: translate(-50%, 0px);
}
.jcc{
  justify-content: center;
}
.ant-btn.btn_public >span+.anticon {
  margin-inline-start: 0px;
}
.ant-btn.btn_public.hasIconMargin >span+.anticon,.ant-btn.btn_public.hasIconMargin >.anticon+span{
  margin-inline-start: 12px;
}
.w55{
  width: 55px;
}
.pr13{
  padding-right: 13px;
}
.itemBG{
  background: #5d453930;
}
.hireCheckbox .ant-checkbox-inner {
    background-color: #000;
    border-color: rgba(255,255,255,1);
}
.hireCheckbox .ant-checkbox-input{
  width: auto;
  height: auto;
}
.hireCheckbox .ant-checkbox-checked .ant-checkbox-inner {
    /* background: linear-gradient(90deg, var(--primary-color) 60%, #000);
    border-color: rgba(255,255,255,.5); */
    border: none;
    background: url(./assets/images/checkBoxed.png);
    background-size: cover;
}
.hireCheckbox .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: #000;
    border-width: 3px;
}
.hireCheckbox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after,.hireCheckbox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .hireCheckbox.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,.hireCheckbox .ant-checkbox-checked:after {
    border-color: rgba(255,255,255,.5);
}
.hireCheckbox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: rgba(255,255,255,.5);
}
.one-line .label {
    margin-right: 2px;
    font-size: 12px;
    align-items: baseline;
}
.one-line .value {
    font-size: 12px;
}
.capsule {
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    height: fit-content;
    padding: 6px 10px 6px 6px;
    border-radius: 14px;
}
.capsule .btn_public {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 6px 24px;
    border-radius: 14px;
    width: fit-content;
}
.chatDropdownMenu{
  width: 240px !important;
  background-color: var(--TP-blue);
  border: 2px solid var(--TP-blue-light);
  /* border-radius: 10px; */
  margin-bottom: 10px;
  max-height: 200px;
  overflow-y: scroll;
}
.chatDropdownlabel{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 5px;
  justify-content: space-between;
  margin-bottom: 5px;
  background-color: var(--bg-black-35);
  border-radius: 6px;
}
.chatDropdownMenu .chatDropdownlabel{
   display: flex;
   

}
.chatDropdownMenu .chatDropdownlabel.selfChat{
  background-color: var(--bg-black-35);
}
.chatDropdownMenu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  width: 100%  !important;
  padding: 0;
}
.chatDropdownMenu.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content{
  display: flex;
}
.chatDropdownMenu.ant-dropdown .ant-dropdown-menu{
  background-color: var(--TP-blue);
  padding: 5px 0;
}
.clubListBg{
  background: url(./assets/images/bgImg/clubListBg.webp);

}
.atackSuccessBg{
  background: url(./assets/images/bgImg/atackSuccessBg.webp);
}
.atackingBg{
  background: url(./assets/images/bgImg/atackingBg.webp);
}
.atackReadyBg{
  background: url(./assets/images/bgImg/atackReadyBg.webp);
}
.atackFailedBg{
  background: url(./assets/images/bgImg/atackFailedBg.webp);
}
.clubListBg,.atackingBg,.atackSuccessBg,.atackReadyBg,.atackFailedBg{
  background-size: auto 100%;
  background-position: center;
}
.ant-modal.lootWrap .ant-modal-content{
  background-color: #00000060;
  border: none;
}
.w120{
  width: 120px;
}
.navBg{
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center top;
}
.aie{
  align-items: end;
}
@keyframes zoom {
  from {
    transform: scale(1.1);
  }

  to {
    transform: scale(1);
  }
}
.zoom{
  animation: zoom 1s ease-in-out infinite alternate;
}
@keyframes zoomer {
  from {
    transform: scale(1.3);
  }

  to {
    transform: scale(1);
  }
}
.zoomer{
  animation: zoomer 1s ease-in-out infinite alternate;
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
.rotate {
  animation: rotate 0.5s ease-in-out;
}


.pl20{
  padding-left: 20px;
}
.pr5{
  padding-right: 5px;
}
.plr9{
  padding: 0 9px;
}

.tweetShareModal .ant-modal-content{
  border: 2px solid var(--white) !important;
  background: rgba(26, 154, 243, 0.90) !important;
  border-radius: 10px !important;
}

.tweetSharedModal .ant-modal-content{
  background: #202020;
}
/* .customTooltip.ant-tooltip .ant-tooltip-content {
    background-color: #000;
} */
.customTooltip .ant-tooltip-inner{
    padding: 0 8px;
    min-height: fit-content;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
}
.mrlow5{
  margin-right: -5px;
}
.noticeBtn{
  height: 34px !important;
  width: 250px !important;
  border-radius: 6px;
  outline: none !important;
  display: flex;
  align-content: center;
  justify-content: center;
  height: auto;
  outline: none !important;
  border: none;
  font-size: 16px;
  display: flex;
  background: var(--white);
  margin: 0 auto;


}
.noticeBtn span{
  color: var(--black);
  font-weight: bold;
}
.fw-300{
  font-weight: 300;
}
.fw-400{
  font-weight: 400;
}
.white-space-pre-wrap{
  white-space: pre-wrap;
}
.min-width110{
  min-width: 110px;
}
.boosterSlider.staticSlider.hireSlider.ant-slider .ant-slider-rail {
  height: 7px;
}
.boosterSlider.staticSlider.hireSlider.ant-slider .ant-slider-track {
  height: 7px;
  background: var(--primary-gradient-verse);
}
.stakingSlider.ant-slider .ant-slider-rail {
  height: 10px;
  background: linear-gradient(90deg, rgba(150, 6, 204, 1) 40%, rgba(65, 0, 89, 1) 100%);
}
.stakingSlider.ant-slider .ant-slider-track {
  height: 10px;
  background: linear-gradient(270deg, rgba(235, 40, 91, 1) 0%, rgba(107, 0, 28, 1) 100%);

}

.useAvatarSwitch.ant-switch.ant-switch-checked{
  background-color: #158413;
}
.useAvatarSwitch.ant-switch.ant-switch-checked:hover{
  background-color: #158413;
}
.useAvatarSwitch.ant-switch{
  background-color: #900A11;
}
.useAvatarSwitch.ant-switch:hover{
  background-color: #900A11;
}

.redPacketFullModel {
  .ant-modal-content  {
    background-color: rgba(0, 0, 0, 0) !important;
    pointer-events: none;
    background-image: none !important;
  }
}


.avatarInfo.roundAvatar .ant-avatar{
  border-radius: 10px;
  border-color: var(--white);
  border-width: 2px;
}

.avatarInfo.momentRoundAvatar .ant-avatar{
  border-radius: 10px;
  border-color: var(--white);
  border-width: 1px;
}

.bidRarity_1{
  background-color: var(--white);
  color: var(--black);
}
.bidRarity_2{
  background-color: var(--white);
  color: var(--black);
}

.web3LoadingLine{
  display: flex;
  align-items: center;
}


.commonSlider.ant-slider .ant-slider-rail{
  background-color: var(--TP-blue-light);
}
.commonSlider.ant-slider .ant-slider-track{
  position: absolute;
  top: 3px;
  height: 10px;
  border-radius: 10px;
  background: var(--TP-yellow);
}
.commonSlider.ant-slider-horizontal .ant-slider-rail{
  height: 10px;
  border-radius: 10px;
  top: 3px;
}
.commonSlider.ant-slider .ant-slider-handle::before{
  width: 18px;
  height: 18px;
}
.commonSlider.ant-slider .ant-slider-handle::after{
  width: 14px;
  height: 14px;
  box-shadow: 0 0 0 2px #fff;
}
.commonSlider.ant-slider .ant-slider-handle{
  width: 14px;
  height: 14px;
}
.commonSlider.ant-slider-horizontal .ant-slider-step{
  top: 4px;
  height: 10px;
}

.momentSlider.ant-slider .ant-slider-rail{
  background: #333333;
  border: 1px solid rgba(153,153,153,1);
}
.momentSlider.ant-slider .ant-slider-track{
  position: absolute;
  top: 3px;
  height: 8px;
  border-radius: 10px;
  background: var(--TP-yellow);
}
.momentSlider.ant-slider-horizontal .ant-slider-rail{
  height: 8px;
  border-radius: 10px;
  top: 3px;
}
.momentSlider.ant-slider .ant-slider-handle::before{
  width: 18px;
  height: 18px;
}
.momentSlider.ant-slider .ant-slider-handle::after{
  width: 14px;
  height: 14px;
  box-shadow: 0 0 0 2px #fff;
}
.momentSlider.ant-slider .ant-slider-handle{
  width: 14px;
  height: 14px;
}
.momentSlider.ant-slider-horizontal .ant-slider-step{
  top: 4px;
  height: 10px;
}

.modelBottomBtn{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,50%);
}

.modelBottomSetGas{
  position: absolute;
  right: 20px;
  bottom: 0;
  transform: translate(50%, 50%);
}

.iconShadow{
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.5));
}
.bgShadow{
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 1));
}

.flex_end_start{
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.flex_end_between{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.flex_baseline_end{
  display: flex;
  align-items: baseline;
  justify-content: end;
}
.flex_center_start{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex_start_center{
  display: flex;
  align-items: start;
  justify-content: center;
}

.flex_start_start{
  display: flex;
  align-items: start;
  justify-content: flex-start;
}

.flex_end_end{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.flex_center_end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex_center_center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_center_between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_start_between{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex_center_start_col{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.flex_start_start_col{
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
}
.flex_end_start_col{
  display: flex;
  align-items: end;
  justify-content: flex-start;
  flex-direction: column;
}

.flex_start_center_col{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

}

.flex_center_end_col{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.flex_center_center_col{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flex_end_center{
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.flex_end_center_col{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.flex_center_between_col{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.fontProtestStrikeLight{
  font-family: 'ProtestStrikeLight', 'Roboto', sans-serif;
}

.fontProtestStrikeRegular{
  font-family: 'ProtestStrikeRegular', 'Roboto', sans-serif;
}

.btn_public .anticon.anticon-loading.anticon-spin path{
  color: var(--black);
}
.btn_white .anticon.anticon-loading.anticon-spin path{
  color: var(--black);
}
.btn_yellow .anticon.anticon-loading.anticon-spin path{
  color: var(--black);
}


.assetProgress{
  width: 79px;
  height: 79px;
  position: absolute;
  top: 0;
  left: 0;
}

.drap-bg-black-5{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.flipped-image {
  transform: scaleX(-1);
}

.buttonFactoryIconDisable .buttonFactoryFlag{
  filter: invert(1);
}
.buttonFactoryIconDisable .iconNoNeedFilter.buttonFactoryFlag{
  filter: none;
}

.rarityWrap{
  padding: 0px 5px;
  border-radius: 20px;
}


.sweepCheckBox .ant-checkbox .ant-checkbox-inner{
  background: var(--bg-black-5);
  border-radius: 50%;
  border:1px solid var(--border-gray);
  width: 14px;
  height: 14px;
}
.sweepCheckBox .ant-radio .ant-radio-inner{
  background: var(--bg-black-5);
  border-radius: 50%;
  border:1px solid var(--border-gray);
  width: 14px;
  height: 14px;
}
.sweepCheckBox.ant-checkbox-wrapper-disabled .ant-checkbox .ant-checkbox-inner{
  background-color: var(--border-gray);
}
.sweepCheckBox.ant-radio-wrapper-disabled .ant-radio .ant-radio-inner{
  background-color: var(--border-gray);
}
.sweepCheckBox .ant-checkbox-checked .ant-checkbox-inner:after{
  display: none;
  width: 0;
  height: 0;
  transform: none;
  transition: none;
}
.sweepCheckBox .ant-radio-checked .ant-radio-inner:after{
  display: none;
  width: 0;
  height: 0;
  transform: none;
  transition: none;
}
.sweepCheckBox .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
  border-color: var(--border-gray);
}
.sweepCheckBox .ant-radio:not(.ant-radio-disabled):hover .ant-radio-inner{
  border-color: var(--border-gray);
}
.sweepCheckBox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner{
  background: rgba(255,218,10,1);
  border-color: rgba(255,218,10,1);
  border-width: 2px;
}
.sweepCheckBox.ant-radio-wrapper:not(.ant-radio-wrapper-disabled):hover .ant-radio-checked:not(.ant-radio-disabled) .ant-radio-inner{
  background: rgba(255,218,10,1);
  border-color: rgba(255,218,10,1);
  border-width: 2px;
}
.sweepCheckBox .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background: rgba(255,218,10,1);
  border-color: rgba(255,218,10,1);
  border-width: 2px;
}
.sweepCheckBox .ant-radio.ant-radio-checked .ant-radio-inner {
  background: var(--white);
  border-color: var(--white);
  border-width: 2px;
}
.sweepCheckBox .ant-checkbox-checked:after{
  border: none;
}
.sweepCheckBox .ant-radio-checked:after{
  border: none;
}
.sweepCheckBox .ant-checkbox-inner {
  background-color: #000;
  border-color: rgba(255,255,255,1);
}
.sweepCheckBox .ant-checkbox-input{
width: auto;
height: auto;
}
.sweepCheckBox .ant-checkbox-checked .ant-checkbox-inner {
  /* background: linear-gradient(90deg, var(--primary-color) 60%, #000);
  border-color: rgba(255,255,255,.5); */
  border: none;
  background: url(./assets/images/checkBoxed.png);
  background-size: cover;
}
.sweepCheckBox .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #000;
  border-width: 3px;
}
.sweepCheckBox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after,.hireCheckbox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .hireCheckbox.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,.hireCheckbox .ant-checkbox-checked:after {
  border-color: rgba(255,255,255,.5);
}
.sweepCheckBox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: rgba(255, 218, 10, 1);
}


.ant-spin-nested-loading .ant-spin-container::after {
  background: none;
}

.upPintsSwiper .swiper-pagination-bullet{
  background-color: #73c1e4;
  opacity: 1;
}
.upPintsSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active{
  background-color: #fff;
  opacity: 1;
}
.ant-select.memeClubSel{
  width: 100%;
}
.ant-select.memeClubSel .ant-select-selector{
  background-color: var(--bg-black-35);
  /* border-color: var(--TP-blue-light); */
  border: 1px solid rgba(255,255,255,0.3);
}

.ant-select.memeClubSel.memeClubSel_disabled{
  background-color: var(--btn-disable-bg-color);
}



.ant-select.memeClubSel .ant-select-selector .ant-select-selection-item{
  color: var(--white);
  display: flex;
  align-items: center;
}

.ant-select.momentLiveSetting .ant-select-selector .ant-select-selection-item{
  font-family: var(--font-moment) !important;
}

.ant-select.memeClubSel .ant-select-arrow{
  position: absolute;
  width: 25px;
  height: calc(100% - 2px) ;
  top: 1px;
  right: 1px;
  margin-top: 0;
}
.ant-select.memeClubSel .ant-select-single .ant-select-selector .ant-select-selection-search{
  z-index: 2;
}
.memeClubSelSuffixIcon{
  width: 25px;
  height: 100%;
  border-radius: 6px;
  background-color: var(--bg-black-35);
  display: flex;
  align-items: center;
  justify-content: center;
}

.memeClubSlider.ant-slider {
  margin: 0;
}

.commonSwitch.ant-switch.ant-switch-checked,.commonSwitch.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled){
  background-color: var(--TP-yellow);
}

.momentSwitch.ant-switch.ant-switch-checked,.momentSwitch.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled){
  background-color: var(--TP-yellow);
}
.momentSwitch.ant-switch.momentSwitch.ant-switch.ant-switch-small .ant-switch-handle{
  width: 15px;
  height: 15px;
  inset-inline-start: 0;
  top: 0;
}
.momentSwitch.ant-switch.momentSwitch.ant-switch.ant-switch-small.ant-switch-checked .ant-switch-handle{
  width: 15px;
  height: 15px;
  inset-inline-start: calc(100% - 14px);
  top: 0;
}

.momentSwitch.ant-switch.ant-switch-small.ant-switch-checked .ant-switch-inner{
  padding-inline-start: 6px;
  padding-inline-end: 11px;
}

.momentSwitch.ant-switch.ant-switch-small .ant-switch-inner {
  padding-inline-start: 11px;
  padding-inline-end: 6px;
}

.momentSwitch.ant-switch.ant-switch-small{
  height: 17px;
}

.momentSwitch{
  border: 1px solid rgba(195,195,195,1);
}

.wrap{
  padding: 0 15px;
  width: 100%;
}

.stickyElmentBg{
  position: sticky;
  top: 0px; 
  z-index: 1000;
  /* background-color: rgba(255, 255, 255, 0.5);  */
  backdrop-filter: blur(10px); 
}

.stickyElment{
  position: sticky;
  top: 0; 
  z-index: 1000;
}


.ant-modal-wrap.img-crop-modal .ant-modal-header{
  background-color: transparent;
  width: 100%;
  height: 95px;
  margin-bottom: 0px;
  display: flex;
  align-items: start;
  justify-content: center;
}
.ant-modal-wrap.img-crop-modal .ant-modal-title{
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  color: var(--white);
}
.ant-modal-wrap.img-crop-modal .ant-modal-close{
  top: 5px;
  inset-inline-end: 8px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper {
  height: 100%;
  overflow: hidden;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select{
  width: 100%;
  height: 100%;
  border: none;
}

.ant-modal-wrap.imgCropModalWrap .ant-modal-content{
  padding: 10px !important;
  border-radius: 0px;
  background-image: url(../public/UI/Picture/UI_Picture_Title-Blue_UP-Notification_01.png);
  background-size: 100% auto;
  background-position: left top;
  background-repeat: no-repeat;
  background-color: var(--model-bg-color);
  border: 2px solid var(--model-border-color);
}

.ant-modal-wrap.imgCropModalWrap .ant-modal-content .ant-modal-footer .ant-btn-default{
  background-color: var(--white);
}
.ant-modal-wrap.imgCropModalWrap .ant-modal-content .ant-modal-footer .ant-btn-default span{
  color: #000;
}
.ant-modal-wrap.imgCropModalWrap .ant-modal-content .ant-modal-footer .ant-btn-primary{
  background-color: var(--TP-yellow);
}
.ant-modal-wrap.imgCropModalWrap .ant-modal-content .ant-modal-footer .ant-btn-primary span{
  color: #000;
}
.ant-modal-wrap.imgCropModalWrap .ant-modal-content .ant-slider .ant-slider-rail{
  background-color: var(--TP-blue-light);
}
.ant-modal-wrap.imgCropModalWrap .ant-modal-content .ant-slider .ant-slider-track{
  background-color: var(--TP-yellow);
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select{
    margin-inline-end: 0px;
    margin-bottom: 0px;
}
.memeClubSlider .ant-slider-dot{
  width: 2px;
  height: 23px;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  border: 0;
  border-radius: 0; 
}

.tabsDot{
  position: absolute;
  right: -5px;
  top: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--TP-yellow);
}  

.commonBeforeInput .ant-input-group .ant-input-group-addon{
  color: var(--color-gray);
  border: none;
  padding-right: 5px;
}
.commonBeforeInput .ant-input-group .ant-input{
  background-color: transparent;
  padding: 4px 0;
  border: none;
}
.commonBeforeInputNumber .ant-input-number .ant-input-number-input{
  padding-right: 0;
}
.commonBeforeInputNumber .ant-input-number{
  background-color: transparent;
}
.commonBeforeInputNumber .ant-input-number-group .ant-input-number-group-addon{
  background-color: transparent;
  border: none;
  color: var(--color-gray);
  padding-left: 0;
}
.swiper{
  width: 100%;
  height: 100%;
}
.loginSwiper{
  width: calc(100% - 40px);
}
.loginSwiper .swiper-wrapper{
  align-items: center;
  width: 100%;  
}

.momentDrawerWarp .momentDrawer{
  background-color: #1B1D28;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding-top: 10px;
  /*border-top: 2px solid #1B1D28;*/
}
.momentDrawerWarp .op9MomentDrawer{
  background-color: rgba(27,29,40,0.9);
}
.ant-drawer .momentDrawer .ant-drawer-body{
  padding: 0px 0;
}
.ant-drawer-content.momentDrawer .ant-drawer-wrapper-body{
  height: 100%;
}

.yellowTabs.ant-tabs >.ant-tabs-nav .ant-tabs-nav-operations{
  display: none;
}
.yellowTabs.ant-tabs >.ant-tabs-nav .ant-tabs-nav-list{
  width: 100%;
}
.yellowTabs.ant-tabs .ant-tabs-tab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yellowTabs.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn{
  font-size: 16px;
  border-bottom: 5px solid transparent;
  /* width: 100%; */
}

.yellowTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 16px;
  /* text-decoration: underline; */
  color: var(--TP-yellow);
  /* border: none; */
  border-color: var(--TP-yellow);
  /* text-decoration: underline; */
  /* text-underline-offset: 5px; */
  /* text-underline-position: above; */
  
}
.yellowTabs.activeSizeChange.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 18px;
}


.yellowTabs.ant-tabs-top >.ant-tabs-nav::before{
  border: none;
}
.yellowTabs.ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar{
  display: none;
}
.yellowTabs.ant-tabs-top >.ant-tabs-nav{
  margin-bottom: 0;
}
.yellowTabs.ant-tabs .ant-tabs-tab+.ant-tabs-tab{
  margin-left: 15px;
}
.yellowTabs.ant-tabs{
  height: 100%;
  width: 100%;
}
.yellowTabs.ant-tabs .ant-tabs-content-holder{
  overflow-y: auto;
}

.ant-tabs.yellowTabs > .ant-tabs-nav .ant-tabs-tab-btn{
  color: var(--white);
  
  /* line-height: 29px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.yellowTabs.ant-tabs .ant-tabs-content,
.yellowTabs.ant-tabs .ant-tabs-content-holder,
.yellowTabs.ant-tabs .ant-tabs-content,
.yellowTabs.ant-tabs .ant-tabs-tabpane
{
  height: 100%;
}
.yellowTabs.tpPlr15 .ant-tabs-nav{
  padding: 0 15px;
}

.momentTabs.ant-tabs >.ant-tabs-nav .ant-tabs-nav-operations{
  display: none;
}
.momentTabs.ant-tabs >.ant-tabs-nav .ant-tabs-nav-list{
  width: 100%;
}
.momentTabs.ant-tabs .ant-tabs-tab {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.momentTabs.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn{
  font-size: 16px;
  border-bottom: none;
  font-family: var(--font-moment) !important;
  line-height: normal;
}

.momentTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 16px;
  color: rgba(255,255,255,1);
  border-color: rgba(255,255,255,1);
  text-shadow: none;
  font-weight: bold;
}
.momentTabs.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn::after{
  content: '';
  position: absolute;
  left: 50%;
  bottom: 5px;
  transform: translateX(-50%);
  background-color: #fff;
  width: 25px;
  height: 5px;
  
}
.momentTabs.activeSizeChange.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 18px;
}


.momentTabs.ant-tabs-top >.ant-tabs-nav::before{
  border: none;
}
.momentTabs.ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar{
  display: none;
}
.momentTabs.ant-tabs-top >.ant-tabs-nav{
  margin-bottom: 0;
}
.momentTabs.ant-tabs .ant-tabs-tab+.ant-tabs-tab{
  margin-left: 15px;
}
.momentTabs.ant-tabs{
  height: 100%;
  width: 100%;
}
.momentTabs.ant-tabs .ant-tabs-content-holder{
  overflow-y: auto;
}

.ant-tabs.momentTabs > .ant-tabs-nav .ant-tabs-tab-btn{
  color: var(--TP-moment-gray);
  font-weight: 400;
  /* line-height: 29px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.momentTabs.ant-tabs .ant-tabs-content,
.momentTabs.ant-tabs .ant-tabs-content-holder,
.momentTabs.ant-tabs .ant-tabs-content,
.momentTabs.ant-tabs .ant-tabs-tabpane
{
  height: 100%;
}
.momentTabs.tpPlr15 .ant-tabs-nav{
  padding: 0 15px;
}

.textNoDrag{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#player00 video{
  overflow: hidden !important;
}

.videoFill video{
  object-fit: fill !important;
}

.videoContain video{
  object-fit: contain !important;
}

.momentCloseBtn{
  position: absolute;
  transform: translateX(-50%);
  bottom: -60px;
  left: 50%;
}

.momentSelect.ant-select.memeClubSel .ant-select-selector .ant-select-selection-item{
  font-family: var(--font-moment) !important;
}

.commonBlueBg{
  position: fixed;
  background-image: url(../public/UI/BG/UI_Background_SpeedLine_01.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}

.ant-modal-root .ant-modal-mask{
  background-color: rgba(0,0,0,0.66) !important;
}

.pointsMoreBanner .slick-list{
  border-radius: 8px;
}

.textSelectNone{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pointBoardSwiper.border-bottomn {
  border-bottom: 1px solid var(--color-gray);
}
.fullModal .pointBoardSwiper .swiper-slide{
  height: calc(100vh - (env(safe-area-inset-bottom) * 2));
}
.pointBoardSwiper .swiper-slide{
  transition: 300ms;
  /* transform: scale(0.8); */
  /*scale: 0.95;*/
  width: 90%;
  aspect-ratio: 0.8263;
}
.pointBoardSwiper .swiper-slide-active{
  scale: 1;
  filter: none;
}

.pointLogPopover .ant-popover-inner{
  background: #000000;
  border-radius: 16px;  
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointLogPopover.ant-popover .ant-popover-arrow:before{
  background: #000000;
}
