.modalWrap{

  .videBg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
    background-position: center;
  }
  .modal{
    
    padding: 20px 20px;
    div{
      color: var(--title);
    }
    span{
      color: var(--red);
      font-size: 15px;
    }
    .pl20{
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 2em;
    }
  }
  .skip{
    // margin: 0 auto;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    bottom: 0;
    text-decoration: underline;
    width: 100%;
    text-align: center;
    padding: 10px;
    padding-bottom: 20px;
  }
}
