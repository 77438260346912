.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--content);
  padding: 20px 30px 45px;
  .img{
    margin: 0 auto;
    //text-align: center;
    // background-image: url(../../assets/images/HWbanner.png);
    // background-size: 80% auto;
    width: 32px;
    top: 10px;
    position: absolute;
    img{
      width: 100%;
    }
  }
}
.momentWrap{
  width: 100%;
  .title{
    margin-top: 24px;
    width: 100%;
    height: 30px;
    font-size: 18px;
    color: #EEEEEE;
    text-align: center;
    font-weight: 600;
  }
  .imageRoot{
    margin-top: 36px;
    .image{
      width: 196px;
    }
  }
  .tips{
    margin-top: 25px;
    width: 100%;
    font-size: 14px;
    color: #EEEEEE;
    text-align: center;
    font-weight: 400;
  }
  .commonBtn{
    width: 220px;
    height: 44px;
    margin: 25px 0px 20px;
  }
}
