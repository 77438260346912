.footNavWrap {
  width: 100%;
  max-width: 500px;
  // background-color: #222325;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 50%;
  height: calc( 68px + (env(safe-area-inset-bottom)/2));
  transform: translateX(-50%);
  background-image: url(../../../public/UI/Picture/UI_Picture-Main_Down_01.png);
  background-size: 100% auto;
  background-repeat: no-repeat; 
  background-position: top center;
  background-color: var(--TP-blue);
  .footNav {
    // padding: 12px;
    // border-radius: 24px 24px 0px 0px;
    // background-color: var(--black);
    // box-shadow: rgba(55, 65, 81, 0.15) 0px 0px 36px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 20px;
    padding-top: 5px;
    .item {
      flex: 1;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      // padding: 1em;
      // border-radius: 50px;
      position: relative;
      .iconWrap{
        height: 40px;
        display: flex;
        align-items: center;
      }
      .label{
        width: 100%;
        padding: 0px 0 0px;
        font-size: 12px;
        color: var(--TP-blue-light);
        //font-family: 'ProtestStrikeRegular', 'Roboto', sans-serif;
        // background-color: #1b1b1d;
        font-weight: bold;
      }
      .dot{
        position: absolute;
        right: 10px;
        top: 10px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--red);
      }
      &.active{
        .label{
          color: var(--white);
        }
      }
    }


  }
  .infoBtn{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 99;
    bottom: 120px;
    right: 28px;
    box-shadow: rgba(55, 65, 81, 0.15) 0px 0px 20px;
  }
}

.showTip {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    padding: 0 8px;
    border-radius: 4px;
    background-color: var(--white);
    width: fit-content;
    color: var(--black);
    font-size: 12px;
    line-height: 1.5;
    &::after {
        content: "";
        position: absolute;
        width: 8.970562748477143px;
        height: 8.970562748477143px;
        bottom: 0;
        inset-inline: 0;
        margin: auto;
        border-radius: 0 0 2px 0;
        transform: translateY(50%) rotate(-135deg);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
        z-index: 0;
        background-color: var(--white);
    }
}

.profileShowTip {
  position: absolute;
  bottom: calc(100% + 10px);
  left: calc(50% - 17px);
  transform: translateX(-50%);
  padding: 0 8px;
  border-radius: 4px;
  background-color: var(--white);
  width: fit-content;
  color: var(--black);
  font-size: 12px;
  line-height: 1.5;
  &::after {
      content: "";
      position: absolute;
      width: 8.970562748477143px;
      height: 8.970562748477143px;
      bottom: 0;
      inset-inline: 0;
      margin: auto;
      border-radius: 0 0 2px 0;
      left: 34%;
      transform: translateY(50%) rotate(-135deg);
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
      z-index: 0;
      background-color: var(--white);
  }
}
